body {
  background-color: #e6e6e6;
}

main {
  padding-bottom: 60px;
}

/* HEADER */
#site-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
}

#site-title a {
  color: black;
  text-decoration: none;
}

.navItem {
  color: black;
  font-size: 24px;
  font-weight: 500;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 20px;
}

.social-icons img {
  float: right;
  text-align: center;
  margin: 10px
}

/* Warning Box */
.Warning {
  background-color: #f5e9b3;
  border-radius: 10px;
  border-color: #e7be05;
  border-width: 3px;
  border-style: solid;
  padding: 25px;
  margin-bottom: 15px;
}

#warning-icon {
  margin-right: 15px;
}

/* Calculator page */
.calculatorFieldset, .calculatorTable {
  display: inline-block;
}

table, tr, td {
  border: 1px solid black;
  margin-top: 10px;
}

table {
  width: 100%;
}

tbody td {
  background-color: white;
}

thead {
  background-color: rgb(132, 127, 136);
}

.social-icons {
  margin-right: 0;
  margin-left: auto;
}

footer {
  height: 60px;
  position: relative;
  margin-top: -60px;
}

/* Weapon List */

.item-card {
  background-color: #a9a9a9;
  margin-top: 25px;
  margin-bottom: 25px;
}

.item-card-cover {
  margin: 0;
}

.item-image, .item-caption {
  display: inline-block;
}

.item-image {
  background-color: rgb(119, 119, 119);
  min-height: 105px;
  font-size: 24px;
  padding: 0;
}

.item-image img {
  width: 100%;
}

.item-card-details table {
  margin: 0;
}

.weapon-card-name {
  font-size: 36px;
  margin-bottom: 0;
}

.weapon-card-tier p {
  font-size: 24px;
  margin-top: 0;
}

/* About Page */

#contact-list {
  list-style-type: none;
}

#contact-list li a {
  text-decoration: none;
}

#contact-list li a {
  color: black;
}

#contact-list li a img {
  margin-right: 10px;
}